<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required} from "vuelidate/lib/validators";
import _debounce from 'lodash.debounce';
import Hosts from "../../../components/campaigns/hosts"
import EmptyList from "@/components/widgets/empty_list"

import {
	userMethods,
	groupMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
	page: {
		title: "Groups",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		Hosts,
		EmptyList
	},
	data() {
		return {
			title: "",
			items: [],
			showModal: false,
			isLoading: false,
			fullPage: true,
			canCancel: false,
			useSlot: false,
			color: "#007bff",
			bgColor: "#ffffff",
			height: 128,
			width: 128,
			
			groups:[],
			users:[],
			group:{},
			submitted:false,
			confirmDelete:false,
			currentPage:1,
			perPage:8,
			rows:0,
			groupId:null,
			hosts:[],
			showHostsPanel:false,
			empty_list_config:{
					"title" : this.$t("groups.empty_search_title"),
					"linkButtonText" : this.$t("common.reset_search"),
					icon: "fa-cubes"
			},
			empty_config:{
					"title" : this.$t("groups.empty_title"),
					"subtitle" : this.$t("groups.empty_text"),
					"buttonText" : this.$t('groups.new_group'),
					icon: "fa-cubes"
			},query:''
		};
	},
	validations: {
		group: {
			name: {
				required,
			},
			description: {
					required,
			}
		}
	},
	mounted() {
		this.loadUsers();
		this.loadGroups();
	},
	created(){
		this.debounceGroups = _debounce(this.searchGroups.bind(this), 1000);
	},
	methods: {
		...userMethods,
		...groupMethods,
		
		onNewGoupClicked(){
			this.group = {}
			this.showModal = true
		},
		loadUsers(){
			let loader = this.$loading.show();
			const params={
				q: `role=host&limit=1000`,
			}
			this.getUsers(params).then((users)=>{
				if(users&& users.data){
					this.users= users.data;

					if(this.group){
						this.hosts = this.users.filter(h=> h.group==this.group._id && h.role=='host');
					}
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title") });
			}).finally(()=>{
				loader.hide();
			});
		},

		loadGroups(){
			let loader = this.$loading.show();
			this.isLoading = true;

			const params={
				q: `limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}&populate[0]=availability`,
			}
			this.getGroups(params).then((groups)=>{
				if(groups&& groups.data){
					this.submitted= false;
					this.groups= groups.data;
					this.rows= groups.totalCount
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("groups.get_groups_error"),title:  this.$t("groups.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
		},

		onConfirmCreateGroupClicked(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			let loader = this.$loading.show();
			this.createGroup(this.group).then(()=>{
				
				this.$notify({ type: 'success', text: this.$t("groups.create_group_success"),title:  this.$t("groups.new_group") });
				this.showModal= false;
				this.currentPage=1;
				this.loadGroups();

			}).catch((()=>{
				this.$notify({ type: 'error', text: this.$t("groups.create_group_error"),title:  this.$t("groups.new_group") });		
			})).finally(()=>{
				this.isLoading = false;
				loader.hide();
				this.submitted = false;
			});
		},

		onConfirmEditGroupClicked(){
			this.submitted = true;
			
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			
			let loader = this.$loading.show();
			this.updateGroup(this.group).then(()=>{
				this.$notify({ type: 'success', text: this.$t("groups.update_group_success"),title:  this.$t("groups.update_group") });
				this.showModal= false;
				this.currentPage=1;
				this.loadGroups();
				this.group={}
			}).catch((()=>{
				this.$notify({ type: 'error', text: this.$t("groups.update_group_error"),title:  this.$t("groups.update_group") });		
			})).finally(()=>{
				loader.hide();
				this.submitted = false;
			});
		},

		onCreateOrEditGroupClicked() {
			if(this.group._id) {
				this.onConfirmEditGroupClicked();
			}else {
				this.onConfirmCreateGroupClicked();
			}
		},

		onDeleteGroupClicked(groupId){
				this.confirmDelete = true;
				this.groupId = groupId
		},

		onEditGroupClicked(group){
				const cloneGroup = Object.assign({}, group);
				this.group = cloneGroup;
				this.showModal= true;
		},

		onConfirmRemoveGroupClicked(){
				let loader = this.$loading.show();
				this.deleteGroup(this.groupId).then(()=>{
						this.$notify({ type: 'success', text: this.$t("groups.delete_group_success"),title:  this.$t("groups.delete_group") });
						this.currentPage=1;
						this.loadGroups();
				}).catch(()=>{
					this.$notify({ type: 'error', text: this.$t("groups.delete_group_error"),title:  this.$t("groups.delete_group") });		
				}).finally(()=>{
						this.isLoading = false;
						loader.hide();
						this.groupId=null;
				})
		},

		onGroupListPageClicked() {
				this.loadGroups();
		},

		onCancelCreateOrEditGroupClicked() {
				this.showModal = false;
				this.group ={};
				this.groupId = null;
		},

		searchGroups(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
			this.empty_list_config.subtitle =  this.$t("groups.empty_search_text").replaceAll('#', this.query);
			if(query){
				this.currentPage = 1;
						const params={
							q: `where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getGroups(params).then((groups)=>{
							if(groups&& groups.data){
								this.groups= groups.data;
								this.rows= groups.totalCount;
							}
							
							
				}).catch(()=>{
							this.$notify({ type: 'error', text: this.$t("groups.get_groups_error"),title:  this.$t("groups.title") });	
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadGroups();
			}
		},
		onHostsClicked(group){
			this.group = group;
			this.hosts = this.users.filter(h=> h.group==group._id && h.role=='host');
			this.showHostsPanel = true;
		},
		removeHostFromGroup(user){
			const u = {
				_id:user._id,
				group:null
			}
			this.updateUser(u).then(()=>{
				this.loadUsers();
			});
		},
		addHostToGroup(user){
			const u = {
				_id:user._id,
				group:this.group._id
			}
			this.updateUser(u).then(()=>{
				this.loadUsers();
			});
		},
		onResetSearchClicked(){
			this.query = '';
      this.loadGroups();
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('groups.title')" :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row ">
							<div class="col-sm-4">
								<div class="search-box me-2 mb-0 d-inline-block">
									<div class="position-relative">
										<input
										type="text"
										class="form-control"
										:placeholder="$t('common.search')"
										v-model="query"
										@input="debounceGroups($event.target.value)"
										/>
										<i class="bx bx-search-alt search-icon"></i>
									</div>
								</div>
							</div>
							<div class="col-sm-8">
								<div class="text-sm-end">
									<button
											type="button"
											class="btn btn-primary mb-0 me-0"
											@click="onNewGoupClicked"
											v-if="groups.length>0 || query!=''">
											<i class="mdi mdi-plus me-1"></i> {{$t('groups.new_group')}}
									</button>
									
									<b-modal
										v-model="showModal"
										:title="group._id? $t('groups.update_group') : $t('groups.new_group')"
										title-class="text-black font-18"
										body-class="p-3"
										hide-footer>

											<form @submit.prevent="onCreateOrEditGroupClicked">
												<div class="row">
													<div class="col-12">
														<div class="mb-3">
															<label for="name">{{$t('groups.new_group_name')}}*</label>
															<input
																id="name"
																v-model="group.name"
																type="text"
																class="form-control"
																:placeholder="$t('groups.new_group_name_placeholder')"
																:class="{'is-invalid':submitted && $v.group.name.$error}"
															/>
															<div v-if="submitted && !$v.group.name.required" class="invalid-feedback">
																{{$t('groups.new_group_name_required')}}
															</div>
														</div>
													</div>
													<div class="col">
														<div class="mb-3">
															<label for="campaignDesc">{{$t('groups.new_group_description')}}*</label>
															<textarea
																id="campaignDesc"
																v-model="group.description"
																class="form-control"
																:placeholder="$t('groups.new_group_description_placeholder')"
																:class="{
																'is-invalid': submitted && $v.group.description.$error,
																}"
																rows="2">
															</textarea>
														<div v-if="submitted && !$v.group.description.required" class="invalid-feedback">
															{{$t('groups.new_group_description_required')}}
														</div>
													</div>
													</div>
												</div>

												<div class="text-end pt-5 mt-3">
													<b-button variant="light" @click="onCancelCreateOrEditGroupClicked">{{$t('common.cancel')}}</b-button>
													<b-button type="submit" variant="primary" class="ms-1">
														{{$t('common.confirm')}}
													</b-button>
												</div>
											</form>
									</b-modal>
								</div>
							</div>
						<!-- end col-->
						</div>
				</div>
				<EmptyList :config="empty_list_config" v-if="!isLoading && groups.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
        <EmptyList :config="empty_config" v-if="!isLoading && groups.length == 0 && query ==''" @onButtonClicked="onNewGoupClicked" class="mt-3 mb-3"/>

				<div class="table-responsive" v-if="!isLoading && groups.length > 0">
					<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
						<thead class="table-light">
							<tr>
								<th scope="col">{{$t('groups.new_group_name')}}</th>
								<th scope="col">{{$t('groups.new_group_description')}}</th>
								<th scope="col">{{$t('groups.new_group_hosts')}}</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="group in groups" :key="group._id">
								<td @click="onEditGroupClicked(group)">
									<h5 class="font-size-14 mb-1">
										<a href="#" class="text-dark">{{group.name}}</a>
									</h5>
								</td>
								<td>{{group.description}}</td>
								<td class="interact" @click="onHostsClicked(group)"> <i class="bx bxs-user-detail"></i> {{users.filter(u=>u.role=='host' && u.group == group._id).length}}</td>
								<td class="text-end">
									<i class="fas fa-edit text-success me-2 interact" v-on:click="onEditGroupClicked(group)"></i>
									<i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onDeleteGroupClicked(group._id)"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveGroupClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
							<p>{{$t('groups.remove_group')}}</p>
					</b-modal>
					</div>
				</div>
				<div class="row mb-4" v-if="!isLoading && rows > perPage">
					<div class="col">
						<div class="dataTables_paginate paging_simple_numbers float-end">
							<ul class="pagination pagination-rounded mb-0">
								<b-pagination
									@input="onGroupListPageClicked"
									v-model="currentPage"
									:total-rows="rows"
									:per-page="perPage">
								</b-pagination>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<b-modal
				title="Hosts"
				v-model="showHostsPanel"
				title-class="text-black font-18"
				body-class="p-3"
				hide-footer>
				<Hosts :users="hosts" :allUsers="users" :showRemove="true" @removeHostFromGroup="removeHostFromGroup" @addHostToGroup="addHostToGroup"></Hosts>
			</b-modal>
		</div>
	</Layout>
</template>

<style scoped>
.interact{
    cursor: pointer;
}
</style>