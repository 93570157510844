<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      selectedHost:null
    };
  },
  methods: {
    onRemoveUserClicked(user){
        this.users = this.users.filter(u => u._id!=user._id);
        this.$root.$emit('hosts',user);
      },
      onRemoveUserFromGroupClicked(user){
        this.users = this.users.filter(u => u._id!=user._id);
        this.$emit('removeHostFromGroup',user);
      },
      onHostSelected(){
        if(this.users.filter(u=>u._id==this.selectedHost._id).length == 0){
          this.users.push(this.selectedHost);
          this.$emit('addHostToGroup',this.selectedHost);
        }

        this.selectedHost = null;
      }
  },
  mounted() {},
  props: {
    updating: {
      type: Boolean,
    },
    users: {
      type: Array,
    },
    showActions: {
      type: Boolean,
    },
    showSelect: {
      type: Boolean,
    },
    showRemove: {
      type: Boolean,
    },
    allUsers:{
      type: Array,
    },
  },
  emits: ['removeHostFromGroup', 'addHostToGroup']
  
};
</script>

<template>
  <div>
    <div class="col-8 mb-3 text-end">
      <multiselect 
        v-model="selectedHost"
        :options="allUsers"
        :selectLabel="$t('common.select')"
        :deselectLabel="$t('common.deselect')"
        :placeholder="$t('groups.select_host_placeholder')"
        :multiple="false"
        track-by="_id" 
        label="name"
        :showNoResults="true"
        open-direction="bottom"
        @input="onHostSelected" >
      </multiselect>
    </div>
  <div class="table-responsive">
      <table class="table align-middle table-nowrap table-hover">
        <thead class="table-light">
          <tr>
              <th scope="col" style="width: 70px;" ></th>
              <th scope="col">{{$t('users.name')}}</th>
              <th scope="col">{{$t('users.email')}}</th>
              <th scope="col"></th>
              <th v-if="showActions" scope="col">{{$t('common.actions')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td v-if="showSelect">
              <b-form-checkbox
                class="form-check" v-model="user.checked">
              </b-form-checkbox>
            </td> 
            <td v-if="!showSelect">
              <div v-if="!user.image" class="avatar-xs">
                  <span class="avatar-title rounded-circle">{{user.name.charAt(0)}}</span>
              </div>
              <div v-if="user.image">
                  <img class="rounded-circle avatar-xs" :src="`${user.image}`" alt />
              </div>
            </td>
            <td>
              <h5 class="font-size-14 mb-1">
                <a href="#" class="text-dark">{{user.name}}</a>
              </h5>
            </td>
            <td>{{user.email}}</td>
            <td v-if="showActions">
              <b-dropdown
                class="card-drop"
                variant="white"
                right
                toggle-class="p-0"
                menu-class="dropdown-menu-end">
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>

                  <b-dropdown-item  @click="onRemoveUserClicked(user)">
                  <i class="fas fa-trash-alt text-danger me-1"></i>
                  {{$t('common.delete')}}
                  </b-dropdown-item>
              </b-dropdown>
            </td>
            <td v-if="showRemove" class="interact" @click="onRemoveUserFromGroupClicked(user)" >
              <i class="fas fa-times text-danger me-1"></i>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</div>
</template>
<style scoped>
.interact{
    cursor: pointer;
}
</style>